<template>
  <mPages>
    <template v-slot:mContent>
      <!--      banner-->
      <div class="banner">
        <div class="banner-img">
          <img src="../../assets/images/mobileImgs/mNews-banner.png" alt="" />
        </div>
      </div>

      <!--      内容-->
      <div class="content">
        <div class="conText w588" v-for="item in newsInfo" :key="item.id">
          <div class="title">{{ item.title }}</div>
          <div class="time">{{ item.date }}</div>
          <div class="word" v-for="subItem in item.texts" :key="subItem.index">
            <p>
              {{ subItem.requirement }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </mPages>
</template>

<script>
import mPages from "@/components/mobile/mPages.vue";
import { getNews } from "@/Api";
import { ref } from "vue";

export default {
  name: "mNews",
  components: {
    mPages,
  },
  setup() {
    const newsInfo = ref([]);
    return {
      newsInfo,
    };
  },
  created() {
    this.getNews();
  },
  methods: {
    getNews() {
      getNews().then((res) => {
        this.newsInfo = res.reverse();
      });
    },
  },
};
</script>

<style scoped lang="scss">
//banner
.banner {
}
//内容
.content {
  .conText {
    padding: 64px 0;
    .title {
      font-size: 32px;
      font-weight: 600;
      color: #34383d;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .time {
      margin: 15px 0 30px;
      font-size: 20px;
      font-weight: 300;
      color: #999;
    }
    .word {
      p {
        font-size: 24px;
        line-height: 40px;
        color: #34383d;
      }
    }
  }
}
.sec {
  background-color: #fafafa;
}
</style>
