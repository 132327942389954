// 导入axios实例
import httpRequest from "@/request/index.js";

// 定义接口的传参
// interface UserInfoParam {
//     userID: string,
//         userName: string
// }

// 社招
export function getJobSociety() {
  return httpRequest({
    url: "/api/societyRecruit/queryAll",
    method: "get",
  });
}

//校招
export function getJobSchool() {
  return httpRequest({
    url: "/api/campusRecruit/queryAll",
    method: "get",
  });
}
//新闻
export function getNews() {
  return httpRequest({
    url: "/api/journalism/queryAll",
    method: "get",
  });
}
