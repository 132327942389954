<template>
  <mPages>
    <template v-slot:mContent>
      <div class="banner">
        <div class="banner-img">
          <img src="../../assets/images/mobileImgs/mJoin-banner.png" alt="" />
        </div>
        <button @click="openBoss">
          <a href="javascript:;">BOSS直聘</a>
        </button>
      </div>
      <div v-if="!recruitTextShow">
        <!--      社招-->
        <div class="societyList recruit">
          <div class="societyListCon w588">
            <div class="title">社会招聘</div>
            <div class="conList">
              <ul v-for="item in jobObjSociety" :key="item.index">
                <li>
                  <div>
                    <!--                    <i class="icon" v-show="item.id === 1">急</i>-->
                    <h3>{{ item.positionName }}</h3>
                  </div>
                  <a href="javascript:;" @click="jobShow(item)">查看岗位></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!--      校招-->
        <div class="schoolList recruit">
          <div class="schoolListCon w588">
            <div class="title">校园招聘</div>
            <div class="conList">
              <ul v-for="item in jobObjSchool" :key="item.index">
                <li>
                  <h3>{{ item.positionName }}</h3>
                  <a href="javascript:;" @click="jobShow(item)">查看岗位></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!--      招聘正文-->
      <div
        class="recruitText w588"
        v-else
        v-for="item in itemDetails"
        :key="item.id"
      >
        <div class="back" @click="backJobList">返回</div>
        <h1>招贤纳士：{{ item.positionName }}</h1>
        <h2>在招职位：</h2>
        <h3 class="st">{{ item.positionName }}</h3>
        <div class="duty recruitInfo">
          <h3>岗位职责：</h3>
          <p v-for="subItem in item.duty" :key="subItem.id">
            {{ subItem.duty }}
          </p>
        </div>
        <div class="requirement recruitInfo">
          <h3>任职要求：</h3>
          <p v-for="subItemo in item.requirement" :key="subItemo.index">
            {{ subItemo.requirement }}
          </p>
        </div>
        <div class="extraPoints" v-if="item.additionalInformation">
          <h3>加分项：</h3>
          <p>
            {{ item.additionalInformation }}
          </p>
        </div>
        <div class="extraPoints" v-if="item.extraPoints">
          <h3>附加信息：</h3>
          <p>
            {{ item.extraPoints }}
          </p>
        </div>
        <div class="email">请将职位及简历发送至:hr_c@hzfjtek.com</div>
      </div>
    </template>
  </mPages>
</template>

<script>
import mPages from "@/components/mobile/mPages";
import { ref } from "vue";
import { getJobSociety, getJobSchool } from "@/Api/index.js";

export default {
  name: "mJoin",
  components: {
    mPages,
  },
  setup() {
    const recruitTextShow = ref(false);
    const jobObjSociety = ref([]);
    const jobObjSchool = ref([]);
    const itemDetails = ref([]);
    return {
      recruitTextShow,
      jobObjSociety,
      jobObjSchool,
      itemDetails,
    };
  },
  created() {
    this.getJobSociety();
    this.getJobSchool();
  },
  methods: {
    //岗位详情
    jobShow(item) {
      this.recruitTextShow = true;
      this.itemDetails.push(item);
    },
    //返回岗位列表
    backJobList() {
      this.itemDetails = this.itemDetails.splice();
      this.recruitTextShow = false;
    },
    //社招岗位信息
    getJobSociety() {
      getJobSociety().then((res) => {
        console.log(res);
        this.jobObjSociety = res.reverse();
      });
    },
    getJobSchool() {
      getJobSchool().then((res) => {
        this.jobObjSchool = res;
      });
    },
    //打开外链boss直聘
    openBoss() {
      window.open(
        "https://www.zhipin.com/job_detail/?query=%E6%9D%AD%E5%B7%9E%E5%87%A1%E4%BD%B6%E4%BA%92%E8%BF%9E%E7%A7%91%E6%8A%80%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&city=101210100&industry=&position=%22"
      );
    },
    // goToBoss() {
    //   let u = navigator.userAgent;
    //   let isAndroid = u.indexOf("Android") > -1; //安卓终端
    //   let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    //   if (isAndroid) {
    //     // 安卓weibo的scheme协议跳转
    //     window.location.href = "sinaweibo://userinfo?uid=3177804914";
    //     setTimeout(function() {
    //       let hidden =
    //           window.document.hidden ||
    //           window.document.mozHidden ||
    //           window.document.msHidden ||
    //           window.document.webkitHidden;
    //       if (typeof hidden == "undefined" || hidden == false) {
    //         window.location.href = "https://m.weibo.cn/u/3177804914";
    //       }
    //     }, 2000);
    //   }
    //   // IOS下的scheme协议跳转
    //   if (isIOS) {
    //     window.location.href = "sinaweibo://userinfo?uid=3177804914";
    //     setTimeout(function() {
    //       let hidden =
    //           window.document.hidden ||
    //           window.document.mozHidden ||
    //           window.document.msHidden ||
    //           window.document.webkitHidden;
    //       if (typeof hidden == "undefined" || hidden == false) {
    //         window.location.href =
    //             "https://m.weibo.cn/u/3177804914";
    //       }
    //     }, 2000);
    //   }
    // },
  },
};
</script>

<style scoped lang="scss">
//banner
.banner {
  position: relative;

  button {
    position: absolute;
    width: 150px;
    height: 42px;
    background-color: #166df7;
    border: none;
    border-radius: 42px;
    left: 66px;
    bottom: 94px;

    a {
      font-size: 18px;
      font-weight: 500;
      color: #fff;
    }
  }
}

//列表
.recruit {
  .w588 {
    padding: 70px 0 64px;

    .title {
      font-size: 32px;
      font-weight: 600;
      text-align: center;
      margin-bottom: 47px;
    }

    .conList {
      ul {
        li {
          height: 148px;
          box-shadow: 0px 4px 24px 0px rgba(32, 34, 36, 0.08);
          margin-bottom: 30px;
          background-color: #fff;
          padding: 35px 0 31px 32px;
          box-sizing: border-box;

          div {
            display: flex;

            .icon {
              background-color: #f3524d;
              color: #fff;
              font-size: 20px;
              display: inline-block;
              margin-right: 12px;
              width: 32px;
              height: 32px;
              line-height: 32px;
              text-align: center;
            }
          }

          h3 {
            font-size: 24px;
            font-weight: 600;
            color: #333;
            margin-bottom: 39px;
          }

          a {
            font-size: 20px;
            color: #166df7;
          }
        }
      }
    }
  }
}

.schoolList {
  background-color: #fafafa;
}

//招聘正文
.recruitText {
  padding: 70px 0 120px;

  .back {
    margin-bottom: 45px;
    width: 80px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    font-size: 24px;
    background-color: #166df7;
    color: #fff;
  }

  h1 {
    font-size: 32px;
    font-weight: 500;
    color: #34383d;
  }

  h2 {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #34383d;
    margin: 52px 0 22px;
  }

  .st {
    font-size: 20px;
    color: #34383d;
    line-height: 35px;
    margin-bottom: 47px;
  }

  .requirement {
    margin: 46px 0 47px;
  }

  .recruitInfo {
    h3 {
      font-size: 24px;
      font-weight: 500;
      color: #34383d;
      margin-bottom: 22px;
    }

    p {
      font-size: 20px;
      color: #34383d;
      line-height: 40px;
    }
  }
  .extraPoints {
    h3 {
      font-size: 24px;
      font-weight: 500;
      color: #34383d;
      margin-bottom: 22px;
    }

    p {
      font-size: 20px;
      color: #34383d;
      line-height: 40px;
    }
  }
  .email {
    text-align: right;
  }
}
</style>
