<template>
  <Pages>
    <template v-slot:content>
      <div class="banner">
        <img src="../assets/images/business.png" />
      </div>
      <div class="w1200">
        <ul id="tabs" class="tabs">
          <li :class="{ active: activeTab == 0 }" @click="changeTab(0)">
            <p>C-DIS高性能通信中间件</p>
            <b>Communication Middleware</b>
          </li>
          <li :class="{ active: activeTab == 1 }" @click="changeTab(1)">
            <p>协同控制系统</p>
            <b>Collaborative control system</b>
          </li>
          <li :class="{ active: activeTab == 2 }" @click="changeTab(2)">
            <p>信创领域</p>
            <b>Information and innovation field</b>
          </li>
        </ul>
      </div>
      <div v-if="activeTab === 0">
        <div class="p-form">
          <div class="w1200">
            <h3 class="title">产品形态</h3>
            <p class="con">
              <b>概述：</b
              >为分布式的、大型复杂的系统提供实时安全的数据交互、信息集成服务，使系统中的各种应用软件及硬件设备更好地协同工作<br />
              <b>特点：</b
              >完全分布式的架构，可扩展架构和动态发现机制，实现自由扩展和动态组网，通过合理的配置QoS策略，可以在微秒级的延迟情况下实现强实时通信
            </p>
          </div>
        </div>
        <div class="ts">
          <div class="w1200">
            <h3 class="title">C-DIS特性</h3>
            <dl>
              <dd>
                <img src="../assets/images/icon4.png" />
                <b>强实时</b>
                <i>微秒级别延时与抖动</i>
              </dd>
              <dd>
                <img src="../assets/images/icon5.png" />
                <b>高灵活</b>
                <i>按场景切换23种QoS<br />配置策略</i>
              </dd>
              <dd>
                <img src="../assets/images/icon6.png" />
                <b>数据安全</b>
                <i>具备多形式的安全保障</i>
              </dd>
              <dd>
                <img src="../assets/images/icon7.png" />
                <b>跨平台</b>
                <i>支持不同操作和网络协议<br />提供统一标准接口</i>
              </dd>
              <dd>
                <img src="../assets/images/icon8.png" />
                <b>扩展性</b>
                <i>发布订阅架构、自主发<br />现、动态组网</i>
              </dd>
              <dd>
                <img src="../assets/images/icon9.png" />
                <b>可靠性</b>
                <i>分布式架构、去中心<br />化、无单点故障</i>
              </dd>
            </dl>
          </div>
        </div>
        <div class="apply">
          <h3 class="title">应用场景</h3>
          <ol class="w1200">
            <li class="dt-left">
              <img src="../assets/images/business01.png" />
              <div class="dtl">
                <h3>航空航天与国防</h3>
                <p>
                  C-DIS
                  具有互操作性、可移植性、松耦合性和实时服务质量等特性，平台适应性强。能最大限度的降低集成、维护和升级成本，并实现快速重新配置，降低系统复杂性。在国防上可以优化军事协同作战、战术边缘到云连接等，建立一个由传感器和作战人员组成的联合协同网络，使空军、陆军、海军陆战队全面协同体系更完善
                </p>
              </div>
            </li>
            <li class="dt-top">
              <img src="../assets/images/business02.png" />
              <div class="dtl">
                <h3>机器人</h3>
                <p>
                  智能机器人为了实现其自主或半自主控制需要构建触觉系统、视觉系统等一系列的仿生体系，那么会伴随着相当数量的传感器和对应的控制设备，他们直接的相互连接和通信就是一个非常重要的一部分，而且对于时间敏感度非常的强。
                </p>
              </div>
            </li>
            <li class="dt-left">
              <img src="../assets/images/business03.png" />
              <div class="dtl">
                <h3>自动驾驶汽车</h3>
                <p>
                  自动驾驶汽车的连动性，需要高性能互连，自主与数据共享。自动驾驶汽车需要处理许多来自于不同传感器的数据流，包括：实时音视频、激光雷达数据(lidar)、雷达数据（radar）、GPS
                  定位数据、控制命令以及异常数据等。这些相关的传感器融合性能要求低延迟、高吞吐量、尽可能的降低系统复杂性、对相关重要参数能够动态配置。
                </p>
              </div>
            </li>
            <li class="dt-left">
              <img src="../assets/images/business04.png" />
              <div class="dtl">
                <h3>石油天然气</h3>
                <p>
                  新的传感器技术、新的分析技术、复杂钻井过程的海量数据流，还要保证钻探的高效益、高效率和小破坏性。承包商和运营商希望能够构建内聚集成平台，优化并简化实时数据分发和共享方式，并能通过远程操作实现自动化和实时监控，提高现场安全和生产率。钻机到水下ROV再到远程操作中心，提供微秒级的传输效率，保障安全生产。
                </p>
              </div>
            </li>
            <li class="dt-bottom">
              <img src="../assets/images/business05.png" />
              <div class="dtl">
                <h3>健康医疗</h3>
                <p>
                  通过分布式实时软件通信框架，简化复杂可扩展的系统之间的连接；使系统和软件架构师能够围绕虚拟数据总线设计医疗系统和子系统；以较低的软件生命周期成本加速联网医疗设备的开发。为复杂的医疗系统提供更快捷先进的数据融合和连接框架。其安全、实时和可互操作的通信，同样满足有着高度灵活，可靠要求的机器人辅助手术系统。
                </p>
              </div>
            </li>
            <li class="dt-left">
              <img src="../assets/images/business06.png" />
              <div class="dtl">
                <h3>模拟仿真</h3>
                <p>
                  通过仿真技术对现实场景进行模拟，不仅可以在设计的早期阶段发现设计问题，验证设计方案的可行性，还能评估设计方案能否在满足约束条件下达到设计目标。C-DIS可以为以数据为中心的分布式仿真系统提供简单的编程模型，为仿真系统开发人员省去了开发事件/消息机制或手动编程获取远程数据等工作。
                </p>
              </div>
            </li>
          </ol>
        </div>

        <div class="system bg-gray">
          <div class="w1200">
            <div><h3 class="title">系统架构</h3></div>
            <img src="../assets/images/business2.png" />
          </div>
        </div>
      </div>
      <div v-if="activeTab === 1">
        <div class="p-form">
          <div class="w1200">
            <h3 class="title">产品形态</h3>
            <p class="con">
              <b>概述：</b
              >模块化、可移植、便于管理的无人机子系统。机载协同任务处理设备功能的增加与升级，可通过软件的远程部署与更新来实现<br />
              <b>特点：</b>采用分布式架构，支持多设备协同控制任务
            </p>
          </div>
        </div>

        <div class="system bg-gray">
          <div class="w1200">
            <div><h3 class="title">硬件架构</h3></div>
            <img src="../assets/images/business3.png" />
          </div>
        </div>
        <div class="system bg-white">
          <div class="w1200">
            <div><h3 class="title">硬件架构</h3></div>
            <img src="../assets/images/business4.png" />
          </div>
        </div>
      </div>
      <div v-if="activeTab === 2">
        <div class="p-form yi">
          <div class="w1200">
            <h3 class="title">社会意义</h3>
            <p class="con">
              省市一体化防疫是平安城市的重要措施和力量，其重心是维护社会稳定、化解基层矛盾、防控违法犯罪，应对大规模公共危机和安保活动。联防联控管理平台把党的群众路线和大数据、云计算、人工智能等新一代信息技术深度融合，创新打造了
              “打、防、管、控” 为一体、多元社会力量和组织立体防控合成作战统一的
              “互联网+” 工作信息平台，实现了 “平战结合”
              ，将人防、物防、技防相结合，开启了全域覆盖的
              “大整合、大联动、大综治” 的社会治理新局面
            </p>
          </div>
        </div>

        <div class="system bg-gray">
          <div class="w1200">
            <div><h3 class="title">技术体系架构</h3></div>
            <p class="con2">
              省级平台技术架构包括“3纵3横”体系,其中“3纵”包括标准规范体系、安全防护体系和运行维护体系，“3横”包括基础设施层、数据资源层以及应用功能层
            </p>
            <img src="../assets/images/business5.png" />
          </div>
        </div>
        <div class="system bg-white">
          <div class="w1200">
            <div><h3 class="title">案例展示</h3></div>
            <img src="../assets/images/business6.png" />
            <b>&nbsp;</b>
            <img src="../assets/images/business7.png" />
          </div>
        </div>
      </div>
    </template>
  </Pages>
</template>
<script>
import { onMounted, ref } from "vue";
import Pages from "../components/Pages.vue";
export default {
  components: { Pages },
  setup() {
    // let activeTab = 0
    let activeTab = ref(0);
    const methods = {
      changeTab(val) {
        activeTab.value = val;
        const ulDom = document.getElementById("tabs");
        const liDom = ulDom.getElementsByTagName("li");
        const liDom2 = document.querySelectorAll(".tabs li");
        for (var j = 0; j < liDom2.length; j++) {
          liDom2[j].className = "";
        }
        if (val > 0) {
          liDom[val - 1].style.borderRightColor = "#fff";
          liDom2[val].className = "active";
        } else {
          liDom2[val].className = "active";
        }
      },
    };
    onMounted(() => {
      const val = activeTab.value;
      const ulDom = document.getElementById("tabs");
      const liDom = ulDom.getElementsByTagName("li");
      if (val > 0) {
        liDom[val - 1].style.border = 0;
      }
    });
    return {
      ...methods,
      activeTab,
    };
  },
  mounted() {
    if (this.$route.query.activeTab) {
      this.activeTab = this.$route.query.activeTab * 1;
    }
  },
};
</script>
<style lang="scss" scoped>
.banner {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabs {
  display: flex;
  margin-top: -80px;
  // margin-left: 100px;
  border: 1px solid #dcdcdc;
  border-bottom: 0;
  li {
    text-align: center;
    height: 111px;
    background: #ffffff;
    border-right: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    flex: 1;
    box-sizing: border-box;
    cursor: pointer;
    &.active {
      box-shadow: 0px 5px 23px 0px rgba(53, 69, 108, 0.1);
      z-index: 2;
      border-right-color: #fff;
      border-bottom: 2px solid #166df7;
      p {
        color: #166df7;
      }
    }
    p {
      font-size: 23px;
      margin-top: 30px;
      color: #1c1d21;
    }
    b {
      font-weight: normal;
      color: #999;
      margin-top: 10px;
      display: block;
    }
  }
}
.title {
  font-size: 24px;
  font-weight: 600;
  color: #333333;
  text-align: center;
  padding: 60px 0 44px;
}
.con {
  color: #35383e;
  line-height: 2.8;
}
.p-form {
  padding-bottom: 50px;
  &.yi {
    .con {
      line-height: 2;
    }
  }
}
.ts {
  background: #fafafa;
  dl {
    display: flex;
    justify-content: space-between;
    padding-bottom: 70px;
    dd {
      width: 185px;
      height: 194px;
      background: #ffffff;
      box-shadow: 0px 2px 9px 0px rgba(32, 34, 36, 0.08);
      text-align: center;
      box-sizing: border-box;
      img {
        width: 46px;
        padding-top: 30px;
        display: block;
        margin: 0 auto;
      }
      b {
        font-weight: 600;
        color: #333;
        font-size: 14;
        display: block;
        margin: 30px 0 10px;
      }
      i {
        display: block;
        color: #35383e;
        // margin-top: 10px;
        line-height: 1.3;
        font-size: 12px;
        transform: scale(0.9);
      }
    }
  }
}
.apply {
  ol {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 50px;
    li {
      width: 400px;
      height: 300px;
      position: relative;
      overflow: hidden;
      img {
        position: relative;
        width: 400px;
        // height: 325px;
        top: 0;
        left: 0;
        transition: all 0.3s;
      }
      &.dt-left {
        .dtl {
          padding: 0 25px;
          background: #002e8d;
          // width: 100%;
          position: absolute;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s;
          h3,
          p {
            position: relative;
            transition: all 0.3s;
          }
          h3 {
            width: 100%;
            left: -100%;
            color: #fff;
            font-size: 22px;
            font-weight: 600;
            margin-top: 40px;
            margin-bottom: 20px;
            text-align: center;
          }
          p {
            padding: 0 15px;
            margin-bottom: 10px;
            line-height: 1.5;
            color: #fff;
            left: 100%;
            text-align: justify;
          }
        }
        &:hover .dtl {
          visibility: visible;
          opacity: 0.8;
          h3,
          p {
            left: 0;
          }
        }
      }
      &.dt-top {
        .dtl {
          padding: 0 25px;
          background: #002e8d;
          // width: 100%;
          position: absolute;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s;
          h3,
          p {
            position: relative;
            top: -100%;
            transition: all 0.3s;
          }
          h3 {
            width: 100%;
            color: #fff;
            font-size: 22px;
            font-weight: 600;
            margin-top: 40px;
            margin-bottom: 20px;
            text-align: center;
            transition-delay: 0.2s;
          }
          p {
            padding: 0 15px;
            margin-bottom: 10px;
            line-height: 1.5;
            color: #fff;
            -webkit-transition-delay: 0.1s;
            -moz-transition-delay: 0.1s;
            -o-transition-delay: 0.1s;
            transition-delay: 0.1s;
            text-align: justify;
          }
        }
        &:hover .dtl {
          visibility: visible;
          opacity: 0.8;
          h3,
          p {
            top: 0;
          }
        }
      }
      &.dt-bottom {
        .dtl {
          padding: 0 25px;
          background: #002e8d;
          // width: 100%;
          position: absolute;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s;
          h3,
          p {
            position: relative;
            top: 100%;
            transition: all 0.3s;
          }
          h3 {
            width: 100%;
            color: #fff;
            font-size: 22px;
            font-weight: 600;
            margin-top: 40px;
            margin-bottom: 20px;
            text-align: center;
            // transition-delay: 0.2s;
          }
          p {
            padding: 0 15px;
            margin-bottom: 10px;
            line-height: 1.5;
            color: #fff;
            transition-delay: 0.1s;
            text-align: justify;
          }
        }
        &:hover .dtl {
          visibility: visible;
          opacity: 0.8;
          h3,
          p {
            top: 0;
          }
        }
      }
    }
  }
}
.system {
  padding-bottom: 130px;
  .con2 {
    color: #35383e;
    margin: 0 0 30px;
  }
  b {
    display: block;
    height: 60px;
  }
}
</style>
