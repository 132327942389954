<template>
  <Pages>
    <template v-slot:content>
      <div class="banner">
        <img src="../assets/images/news.png"/>
      </div>
      <div class="w1200">
        <div class="breadcrumb">
          <router-link to="/">首页</router-link>
          <img
              src="../assets/images/right.png"
              class="icon-right"
          />新闻资讯<img
            src="../assets/images/right.png"
            class="icon-right"
        /><span>正文</span>
        </div>
        <div class="desc" v-for="item in newsInfo" :key="item.id">
          <h3>{{ item.title }}</h3>
          <span>{{ item.date }}</span>
          <p v-for="subItem in item.text" :key="subItem.index" style="text-indent: 28px">
            {{ subItem }}
          </p>
          <!--          <div class="imgBox">-->
          <!--            <img v-for="subItem in item.localStorageDtos" :key="subItem.index" :src="`${imgPath}` + subItem.realName" alt="">-->
          <!--          </div>-->
        </div>
      </div>
    </template>
  </Pages>
</template>

<script>
// const imgPath = 'http://loantest.jclweb.cn/file/图片/'
import Pages from "../components/Pages.vue";
// import {getNews} from "@/Api";
import {ref} from "vue";

export default {
  name: "news",
  components: {
    Pages,
  },
  setup() {
    const newsInfo = ref([
      {
        id: 6,
        title: '凡佶互连成功入选杭州市2023年度第二批创新型中小企业',
        date: '2023-07-14',
        text: ["日前，杭州市经济和信息化局公示了杭州市2023年度第二批创新型中小企业评价结果，杭州凡佶互连有限公司成功入选2023年度第二批创新型中小企业。",
            "作为一家聚焦技术研发、科研与市场并重运行的高新科技型企业，凡佶互连依托浙江大学计算机、信息、电子、光电、控制等学科的有力支撑，已与多个国家级的科研院所建立了良好的合作关系，目前已有多个科研项目在有序进行，并在科研成果转换方面取得了积极的进展。",
            "创新型中小企业，是指具有较高专业化水平、较强创新能力和发展潜力，是优质中小企业的基础力量。此次成功获评创新型中小企业，不仅充分肯定了企业的科技创新能力和前沿核心技术，也有助于企业在产业空间、资源倾斜等多角度上获得政府政策倾向，为未来申报专精特新企业、专精特新“小巨人”企业等打下基础。未来，凡佶互连将不断夯实自身核心竞争力，持续提高创新能力与研发水平，走向“专精特新”发展之路。"]
  },
          {
            id:5,
            title:'公司顺利通过ISO体系认证',
            date:'2021-12-02',
            text:[
              '公司于2021年12月2日顺利通过了ISO体系的认证，获得了ISO9001质量管理体系认证证书、ISO14001环境管理体系认证证书以及ISO45001职业健康安全管理体系认证证书。',
              '此次认证范围包括：计算机信息系统集成、应用软件开发和技术服务、嵌入式系统软硬件技术开发和相关管理活动。',
              '此次认证标志着公司在质量、环境、职业健康安全管理上更加规范化、标准化。',
              '我们将不断加强公司的综合研发能力，扩大认证范围；进一步优化和规范各项管理制度，提升公司的管理水平，树立品牌形象，为顾客提供强有力的保障。',
            ]
          },
          {
            id:4,
            title:'产学研深度融合校企创新协同发展',
            date:'2022-02-18',
            text:[
              '为了践行习近平总书记在中国共产党第十九次全国代表大会上的报告指出的：“深化科技体制改革,建立以企业为主体、市场为导向、产学研深度融合的技术创新体系,加强对中小企业创新的支持,促进科技成果转化”的要求。',
              '公司自成立起，便专注于电子信息领域的市场研究和技术创新，始终坚持面向市场需求，紧随行业技术发展动态，通过持续创新，开发具有自主知识产权的产品及服务，进而提升企业的核心竞争力。',
              '公司技术开发团队主要由高级工程师、软件设计师、高级架构师、助理研究员等技术人员组成的高技术团队，技术团队结构合理，具备领先的技术方向、丰富的技术开发和较强的科技成果产业化能力。',
              '为了进一步提升公司团队的研发能力，公司聘请浙江大学计算机软件研究所副所长，浙江省智慧医疗创新中心技术委员会主任，浙江大学智能教育研究中心主任应晶教授作为首席科学家。',
              '公司携手浙江大学智能软件研究室应晶教授团队，共同参与大数据、人工智能、物联网等技术领域的研究、开发、应用及成果转化，建立企业技术研发中心，专门用于管理技术创新，项目研发等工作。',
              '同时公司大力开展高质量人才的引进工作，高度重视员工的创新能力，目前公司专职技术开发人员占职工总人数的85%，其中硕士学历占职工总人数的30%。未来致力于成为国内一家电子信息领域的高新技术企业。',
            ]
          },
          {
            id:3,
            title:'凡佶科技顺利入库科技型中小企业',
            date:'2022-06-17',
            text:[
              '6月17日，浙江省科学技术厅公布了《浙江省2022年第2批入库科技型中小企业名单公告》，凡佶科技凭借科技人员、研发投入、科技成果等多方面优势，成功入库科技型中小企业。',
              '凡佶科技成立于2019年1月，主要业务是系统开发、软件技术服务、软件研发、信息技术咨询、技术交流、技术成果转让、技术产品推广、信息系统集成服务等，是一家科技研发水平及专业化程度高的公司。',
              '公司携手浙江大学智能软件研究室应晶教授团队，共同参与大数据、人工智能、物联网等技术领域的研究、开发、应用及成果转化。',
              '与各大知名企业、科研单位展开了广泛深入的合作，成果显著，未来致力于成为国内一家电子信息领域的高新技术企业。',
              '同时，严格的质量管理体系、环境管理体系、中国职业健康安全管理体系使凡佶互连的技术团队成为公司高品质产品和服务的有力保障。',
            ]
          },
          {
            id:2,
            title:'凡佶科技顺利通过杭州市“雏鹰计划”企业认定名单公示期',
            date:'2022-07-29',
            text:[
              '7月22日杭州市科学技术局发布了《关于2022年杭州市“雏鹰计划”企业拟认定名单的公示》，凡佶科技在技术创新、公司团队、财务情况、商业模式及成长性方面，经区科技部门审核推荐，经专家评审，以多方面的优势成功入选了2022年杭州市“雏鹰计划”企业拟认定名单，并且顺利通过了2022年7月22日至2022年7月28日的公示期。\n' +
              '公司自成立起，便专注于电子信息领域的市场研究和技术创新，始终坚持面向市场需求，紧随行业技术发展动态，通过持续创新，开发具有自主知识产权的产品及服务，进而提升企业的核心竞争力。',
              '公司自主建立企业技术研发中心，专门用于管理技术创新，项目研发等工作，同时公司大力开展高质量人才的引进工作，高度重视员工的创新能力。',
            ]
          },
    {
      id:1,
      title:'凡佶科技成功入选浙江省科技型中小企业认定名单',
      date:'2022-08-11',
      text:[
        '8月11日杭州市科学技术局公布了《关于杭州市2022年第一批省科技型中小企业拟认定名单的公示》，经区科技局审核推荐、项目管理中心复核等程序，凡佶科技顺利入选。',
        '公司自成立起，便专注于电子信息领域的市场研究和技术创新，始终坚持面向市场需求，紧随行业技术发展动态，通过持续创新，开发具有自主知识产权的产品及服务，进而提升企业的核心竞争力。',
        ' 公司自主建立企业技术研发中心，专门用于管理技术创新，项目研发等工作，同时公司大力开展高质量人才的引进工作，高度重视员工的创新能力，目前公司专职技术开发人员占职工总数85%，其中硕士学历占职工总数30%。',
      ]
    },
  ])
    ;
    return {
      newsInfo,
      // imgPath
    };
  },
  created() {
    this.getNews();
  },
  methods: {
    getNews() {
      console.log('111')
      // getNews().then((res) => {
      //   console.log(res);
      //   this.newsInfo = res.reverse();
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadcrumb {
  display: flex;
  align-items: center;
  color: #333;
  margin: 24px 0 44px;

  a {
    color: #0d1624;
  }

  img {
    margin: 0 10px;
  }

  .icon-right {
    width: 10px;
    height: 12px;
  }

  span {
    color: #999;
  }
}

.desc {
  text-align: left;
  padding: 15px 0 60px 0;

  h3 {
    font-size: 24px;
    font-weight: 600;
    color: #34383d;
    margin-bottom: 14px;
    text-align: left;
  }

  span {
    color: #999;
    font-size: 14px;
    margin-bottom: 31px;
    display: block;
  }

  p {
    color: #34383d;
    line-height: 2;
    font-size: 14px;
  }

  &.team {
    margin: 50px 0;
  }

  .imgBox {
    display: flex;
    flex-direction: row;

    img {
      width: 300px;
    }
  }
}
</style>
