<template>
  <mPages>
    <template v-slot:mContent>
      <div class="banner">
        <div class="banner-img">
          <img
            src="../../assets/images/mobileImgs/mBusinese-banner.png"
            alt=""
          />
        </div>
      </div>
      <!--      tab导航-->
      <div class="tabNav w588">
        <div
          class="tabs"
          :class="{ active: isActive == 0 }"
          @click="changeTab(0)"
        >
          <p>C-DIS高性能通信中间件</p>
          <i>Communication Middleware</i>
        </div>
        <div
          class="tabs"
          :class="{ active: isActive == 1 }"
          @click="changeTab(1)"
        >
          <p>协同控制系统</p>
          <i>Collaborative control system</i>
        </div>
        <div
          class="tabs"
          :class="{ active: isActive == 2 }"
          @click="changeTab(2)"
        >
          <p>信创领域</p>
          <i>Information and innovation field</i>
        </div>
      </div>

      <!--      内容-->
      <!--      c-dis-->
      <div class="cDis" v-if="isActive == 0">
        <!--        产品形态-->
        <div class="cDisXingtai w588">
          <h3>产品形态</h3>
          <p>
            <b>概述：</b
            >为分布式的、大型复杂的系统提供实时安全的数据交互、信息集成服务，使系统中的各种应用软件及硬件设备更好地协同工作
          </p>
          <p>
            <b>特点：</b
            >完全分布式的架构，可扩展架构和动态发现机制，实现自由扩展和动态组网，通过合理的配置QoS策略，可以在微秒级的延迟情况下实现强实时通信
          </p>
        </div>
        <!--        C-DIS特性-->
        <div class="characteristic">
          <div class="info w588">
            <h3>C-DIS特性</h3>
            <div class="info-box">
              <div class="everyOne">
                <div class="img">
                  <img src="../../assets/images/icon4.png" alt="" />
                </div>
                <div class="title">强实时</div>
                <p class="text">微秒级别延时与抖动</p>
              </div>
              <div class="everyOne">
                <div class="img">
                  <img src="../../assets/images/icon5.png" alt="" />
                </div>
                <div class="title">高灵活</div>
                <p class="text">按场景切换23种QoS<br />配置策略</p>
              </div>
              <div class="everyOne">
                <div class="img">
                  <img src="../../assets/images/icon6.png" alt="" />
                </div>
                <div class="title">数据安全</div>
                <p class="text">具备多形式的安全保障</p>
              </div>
              <div class="everyOne">
                <div class="img">
                  <img src="../../assets/images/icon7.png" alt="" />
                </div>
                <div class="title">跨平台</div>
                <p class="text">支持不同操作和网络协议提供统一标准接口</p>
              </div>
              <div class="everyOne">
                <div class="img">
                  <img src="../../assets/images/icon8.png" alt="" />
                </div>
                <div class="title">扩展性</div>
                <p class="text">发布订阅架构、自主发<br />现、动态组网</p>
              </div>
              <div class="everyOne">
                <div class="img">
                  <img src="../../assets/images/icon9.png" alt="" />
                </div>
                <div class="title">可靠性</div>
                <div class="text">分布式架构、去中心<br />化、无单点故障</div>
              </div>
            </div>
          </div>
        </div>
        <!--        应用场景-->
        <div class="scene">
          <div class="sceneBox w588">
            <h3 class="title">应用场景</h3>
            <ul>
              <li class="dt-left">
                <img src="../../assets/images/business01.png" alt="" />
                <div class="text dtl">
                  <div class="title">航空航天与国防</div>
                  <div class="con">
                    C-DIS
                    具有互操作性、可移植性、松耦合性和实时服务质量等特性，平台适应性强。能最大限度的降低集成、维护和升级成本，并实现快速重新配置，降低系统复杂性。在国防上可以优化军事协同作战、战术边缘到云连接等，建立一个由传感器和作战人员组成的联合协同网络，使空军、陆军、海军陆战队全面协同体系更完善
                  </div>
                </div>
              </li>
              <li>
                <img src="../../assets/images/business02.png" alt="" />
                <div class="text">
                  <div class="title">机器人</div>
                  <div class="con">
                    智能机器人为了实现其自主或半自主控制需要构建触觉系统、视觉系统等一系列的仿生体系，那么会伴随着相当数量的传感器和对应的控制设备，他们直接的相互连接和通信就是一个非常重要的一部分，而且对于时间敏感度非常的强。
                  </div>
                </div>
              </li>
              <li>
                <img src="../../assets/images/business03.png" alt="" />
                <div class="text">
                  <div class="title">自动驾驶汽车</div>
                  <div class="con">
                    自动驾驶汽车的连动性，需要高性能互连，自主与数据共享。自动驾驶汽车需要处理许多来自于不同传感器的数据流，包括：实时音视频、激光雷达数据(lidar)、雷达数据（radar）、GPS
                    定位数据、控制命令以及异常数据等。这些相关的传感器融合性能要求低延迟、高吞吐量、尽可能的降低系统复杂性、对相关重要参数能够动态配置。
                  </div>
                </div>
              </li>
              <li>
                <img src="../../assets/images/business04.png" alt="" />
                <div class="text">
                  <div class="title">石油天然气</div>
                  <div class="con">
                    新的传感器技术、新的分析技术、复杂钻井过程的海量数据流，还要保证钻探的高效益、高效率和小破坏性。承包商和运营商希望能够构建内聚集成平台，优化并简化实时数据分发和共享方式，并能通过远程操作实现自动化和实时监控，提高现场安全和生产率。钻机到水下ROV再到远程操作中心，提供微秒级的传输效率，保障安全生产。
                  </div>
                </div>
              </li>
              <li>
                <img src="../../assets/images/business05.png" alt="" />
                <div class="text">
                  <div class="title">健康医疗</div>
                  <div class="con">
                    通过分布式实时软件通信框架，简化复杂可扩展的系统之间的连接；使系统和软件架构师能够围绕虚拟数据总线设计医疗系统和子系统；以较低的软件生命周期成本加速联网医疗设备的开发。为复杂的医疗系统提供更快捷先进的数据融合和连接框架。其安全、实时和可互操作的通信，同样满足有着高度灵活，可靠要求的机器人辅助手术系统。
                  </div>
                </div>
              </li>
              <li>
                <img src="../../assets/images/business06.png" alt="" />
                <div class="text">
                  <div class="title">模拟仿真</div>
                  <div class="con">
                    通过仿真技术对现实场景进行模拟，不仅可以在设计的早期阶段发现设计问题，验证设计方案的可行性，还能评估设计方案能否在满足约束条件下达到设计目标。C-DIS可以为以数据为中心的分布式仿真系统提供简单的编程模型，为仿真系统开发人员省去了开发事件/消息机制或手动编程获取远程数据等工作。
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!--        系统架构-->
        <div class="system">
          <div class="systemInfo w588">
            <h3 class="title">系统架构</h3>
            <img src="../../assets/images/business2.png" alt="" />
          </div>
        </div>
      </div>

      <!--      协同控制-->
      <div class="control" v-else-if="isActive == 1">
        <!--        产品形态-->
        <div class="controlXingtai w588">
          <h3>产品形态</h3>
          <p>
            <b>概述：</b
            >模块化、可移植、便于管理的无人机子系统。机载协同任务处理设备功能的增加与升级，可通过软件的远程部署与更新来实现
          </p>
          <p><b>特点：</b>采用分布式架构，支持多设备协同控制任务</p>
        </div>
        <!--        硬件架构-->
        <div class="hardware">
          <div class="hardwareInfo w588">
            <div class="title">硬件架构</div>
            <img
              class="imgHardware"
              src="../../assets/images/business3.png"
              alt=""
            />
          </div>
        </div>
        <div class="hardware" style="background-color: #fff">
          <div class="hardwareInfo w588">
            <div class="title">硬件架构</div>
            <img
              class="imgHardware"
              src="../../assets/images/business4.png"
              alt=""
            />
          </div>
        </div>
      </div>

      <!--      信创领域-->
      <div class="xinchuang" v-else>
        <div class="yiyi w588">
          <h3>社会意义</h3>
          <p>
            省市一体化防疫是平安城市的重要措施和力量，其重心是维护社会稳定、化解基层矛盾、防控违法犯罪，
            应对大规模公共危机和安保活动。联防联控管理平台把党的群众路线和大数据、云计算、人工智能等新一代信息技术深度融合，
            创新打造了 “打、防、管、控”
            为一体、多元社会力量和组织立体防控合成作战统一的 “互联网+”
            工作信息平台，实现了 “平战结合” ，
            将人防、物防、技防相结合，开启了全域覆盖的 “大整合、大联动、大综治”
            的社会治理新局面。
          </p>
        </div>
        <div class="hardware">
          <div class="hardwareInfo w588">
            <div class="title">硬件架构</div>
            <p class="subTitle">
              省级平台技术架构包括“3纵3横”体系,其中“3纵”包括标准规范体系、安全防护体系和运行维护体系，“3横”包括基础设施层、数据资源层以及应用功能层
            </p>
            <img
              class="imgHardware"
              src="../../assets/images/business5.png"
              alt=""
            />
          </div>
        </div>
        <div class="caseShow">
          <div class="caseImg w588">
            <div class="title">案例展示</div>
            <img src="../../assets/images/business6.png" alt="" />
            <br />
            <img src="../../assets/images/business7.png" alt="" />
          </div>
        </div>
      </div>
    </template>
  </mPages>
</template>

<script>
import mPages from "@/components/mobile/mPages";
import { ref } from "vue";
export default {
  name: "mBusiness",
  components: {
    mPages,
  },
  setup() {
    const isActive = ref(0);
    return {
      isActive,
    };
  },
  mounted() {
    if (this.$route.query.activeTab) {
      this.isActive = this.$route.query.activeTab * 1;
    }
  },
  methods: {
    changeTab(val) {
      this.isActive = val;
    },
  },
};
</script>

<style scoped lang="scss">
//nav导航
.tabNav {
  display: flex;
  margin-top: -78px;
  margin-bottom: 10px;
  .tabs {
    width: 198px;
    height: 164px;
    border: 1px solid #dcdadd;
    background-color: #fafafa;
    padding: 0 16px;
    box-sizing: border-box;
    p {
      font-size: 26px;
      font-weight: 500;
      text-align: center;
    }
    i {
      font-size: 16px;
      color: #999999;
      line-height: 18px;
      text-align: center;
      display: block;
    }
  }
  .active {
    border-bottom-color: #166df7;
    background-color: #fff;
    p {
      color: #166df7;
    }
  }
  .tabs:nth-child(1) {
    padding-top: 31px;
    p {
      margin-bottom: 15px;
    }
  }
  .tabs:nth-child(2) {
    border-right: none;
    border-left: none;
    padding-top: 45px;
    p {
      margin-bottom: 31px;
    }
  }
  .tabs:nth-child(3) {
    padding-top: 45px;
    p {
      margin-bottom: 31px;
    }
  }
}

//内容
//C-DIS
.cDis {
  .cDisXingtai {
    padding: 64px 0;
    h3 {
      text-align: center;
      margin-bottom: 44px;
      font-size: 32px;
      font-weight: 600;
      color: #333333;
    }
    p {
      font-size: 24px;
      color: #35383e;
      line-height: 48px;
      b {
        font-weight: 600;
      }
    }
  }
  .characteristic {
    background-color: #fafafa;
    .info {
      padding: 64px 0;
      h3 {
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 47px;
      }
      .info-box {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .everyOne {
          margin-bottom: 24px;
          width: 180px;
          height: 232px;
          background-color: #fff;
          padding: 42px 0 30px;
          box-sizing: border-box;
          text-align: center;
          .img {
            width: 60px;
            margin: auto;
          }
          .title {
            margin: 31px 0 16px;
            font-size: 18px;
            font-weight: 600;
            color: #333333;
          }
          .text {
            padding: 0 13px;
            font-size: 14px;
            color: #35383e;
            line-height: 20px;
          }
        }
      }
    }
  }
  .scene {
    .sceneBox {
      padding: 64px 0;
      .title {
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 47px;
      }
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 50%;
          height: 222px;
          overflow: hidden;
          position: relative;
          img {
            width: 100%;
          }
          &.dt-left {
            .dtl {
              padding: 0 25px;
              background: #002e8d;
              // width: 100%;
              position: absolute;
              height: 100%;
              top: 0;
              left: 0;
              opacity: 0;
              visibility: hidden;
              transition: all 0.3s;
              h3,
              p {
                position: relative;
                transition: all 0.3s;
              }
              h3 {
                width: 100%;
                left: -100%;
                color: #fff;
                font-size: 22px;
                font-weight: 600;
                margin-top: 40px;
                margin-bottom: 20px;
                text-align: center;
              }
              p {
                padding: 0 15px;
                margin-bottom: 10px;
                line-height: 1.5;
                color: #fff;
                left: 100%;
                text-align: justify;
              }
            }
            &:hover .dtl {
              visibility: visible;
              opacity: 0.8;
              h3,
              p {
                left: 0;
              }
            }
          }
          .text {
            position: absolute;
            top: 100%;
            padding: 12px 12px 0;
            box-sizing: border-box;
            background-color: #002e8d;
            height: 222px;
            opacity: 0.8;
            transition: all 0.3s;
            overflow: scroll;
            .title {
              font-size: 18px;
              font-weight: 600;
              color: #ffffff;
              line-height: 18px;
              margin-bottom: 10px;
            }
            .con {
              font-size: 12px;
              font-weight: 500;
              color: #fff;
              line-height: 22px;
              text-shadow: 0px 4px 24px rgba(32, 34, 36, 0.2);
            }
          }
        }
        li:hover {
          .text {
            //margin-top: -222px;
            top: 0;
            //z-index: 100;
          }
        }
      }
    }
  }
  .system {
    background-color: #fafafa;
    .systemInfo {
      padding: 64px 0;
      .title {
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 47px;
      }
    }
  }
}

//协同控制系统
.control {
  .controlXingtai {
    padding: 64px 0;
    h3 {
      text-align: center;
      margin-bottom: 44px;
      font-size: 32px;
      font-weight: 600;
      color: #333333;
    }
    p {
      font-size: 24px;
      color: #35383e;
      line-height: 48px;
      b {
        font-weight: 600;
      }
    }
  }
  .hardware {
    background-color: #fafafa;
    padding: 64px 0;
    .hardwareInfo {
      text-align: center;
      .title {
        font-size: 32px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 47px;
      }
      .imgHardware {
      }
    }
  }
}

//信创领域
.xinchuang {
  .yiyi {
    padding: 64px 0;
    h3 {
      text-align: center;
      margin-bottom: 44px;
      font-size: 32px;
      font-weight: 600;
      color: #333333;
    }
    p {
      font-size: 24px;
      color: #35383e;
      line-height: 48px;
    }
  }
  .hardware {
    background-color: #fafafa;
    padding: 64px 0;
    .hardwareInfo {
      text-align: center;
      .title {
        font-size: 32px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 47px;
      }
      .subTitle {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 22px;
      }
      .imgHardware {
      }
    }
  }
  .caseShow {
    padding: 64px 0;
    .caseImg {
      text-align: center;
      .title {
        font-size: 32px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 47px;
      }
    }
  }
}
</style>
