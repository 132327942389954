<template>
  <Pages>
    <template v-slot:content>
      <div>
        <div class="banner">
          <img src="../assets/images/join.png" />
          <button @click="bossClick">
            <a href="javascript:;">BOSS直聘</a>
          </button>
        </div>
        <div v-if="activeTab === false">
          <div class="w1200">
            <div class="breadcrumb">
              <router-link to="/">首页</router-link>

              <img
                src="../assets/images/right.png"
                class="icon-right"
              />加入我们<img
                src="../assets/images/right.png"
                class="icon-right"
              /><span>正文</span>
            </div>
          </div>
          <!--          社招-->
          <div class="desc w1200">
            <h3>社会招聘</h3>
            <div class="post" v-for="item in jobObjSociety" :key="item.index">
              <!--            岗位-->
              <!--            急招-->
              <div class="top">
                <!--                <span class="emergency">急</span>-->
                <div class="title">{{ item.positionName }}</div>
              </div>
              <div class="link">
                <router-link to="" @click="changeTab(item)"
                  >查看岗位>
                </router-link>
              </div>
            </div>
          </div>
          <!--          校招-->
          <div class="culture">
            <div class="w1200">
              <h3>校园招聘</h3>
              <div class="sPost" v-for="item in jobObjSchool" :key="item.index">
                <!--            岗位-->
                <div class="top">
                  <div class="title">{{ item.positionName }}</div>
                </div>
                <div class="link" @click="changeTab(item)">
                  <router-link to="">查看岗位></router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--      加入我们正文-->
        <div class="joinText" v-else>
          <div class="w1200">
            <div class="breadcrumb">
              <router-link to="/">首页</router-link>
              <img
                src="../assets/images/right.png"
                class="icon-right"
              />加入我们
              <img src="../assets/images/right.png" class="icon-right" />
              <i @click="activeTabHidden" style="cursor: pointer">岗位招聘</i>
              <img src="../assets/images/right.png" class="icon-right" /><span
                >正文</span
              >
            </div>

            <!--            内容-->
            <div class="info">
              <!--              要求-->
              <div
                class="require"
                v-for="item in itemDetails"
                :key="item.index"
              >
                <h2>招贤纳士：{{ item.positionName }}</h2>
                <h3>在招职位：</h3>
                <p>{{ item.positionName }}</p>
                <h3>岗位职责：</h3>
                <p v-for="subItem in item.duty" :key="subItem.id">
                  {{ subItem.duty }}
                </p>
                <h3>任职要求：</h3>
                <p v-for="subItem in item.requirement" :key="subItem.id">
                  {{ subItem.requirement }}
                </p>
                <p v-if="item.additionalInformation">
                  {{ item.additionalInformation }}
                </p>
                <p v-if="item.extraPoints">附加信息：{{ item.extraPoints }}</p>
              </div>
              <!--              列表-->
              <div class="list">
                <p class="list-title">招聘列表</p>
                <ul
                  class="list-box"
                  v-for="item in jobObjSociety"
                  :key="item.index"
                >
                  <li class="box-info" @click="changeListTab(item)">
                    {{ item.positionName }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Pages>
</template>

<script>
import Pages from "../components/Pages";
import { ref } from "vue";
import { getJobSociety, getJobSchool } from "@/Api/index.js";

export default {
  name: "join",
  components: {
    Pages,
  },
  setup() {
    const activeTab = ref(false);
    const jobObjSociety = ref([]);
    const jobObjSchool = ref([]);
    const itemDetails = ref([]);
    const listTitle = ref("");
    return {
      activeTab,
      jobObjSociety,
      jobObjSchool,
      itemDetails,
      listTitle,
    };
  },
  created() {
    this.getJobSchool();
    this.getJobSociety();
  },
  methods: {
    changeTab(item) {
      this.activeTab = true;
      this.itemDetails.push(item);
    },
    changeListTab(item) {
      this.itemDetails = this.itemDetails.splice();
      this.itemDetails.push(item);
    },
    activeTabHidden() {
      this.activeTab = !this.activeTab;
      this.itemDetails = this.itemDetails.splice();
    },
    bossClick() {
      window.open(
        "https://www.zhipin.com/job_detail/?query=%E6%9D%AD%E5%B7%9E%E5%87%A1%E4%BD%B6%E4%BA%92%E8%BF%9E%E7%A7%91%E6%8A%80%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&city=101210100&industry=&position="
      );
    },
    getJobSchool() {
      getJobSchool().then((res) => {
        this.jobObjSchool = res;
      });
    },
    getJobSociety() {
      getJobSociety().then((res) => {
        this.jobObjSociety = res;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.banner {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  button {
    position: absolute;
    display: block;
    bottom: 125px;
    left: 365px;
    width: 128px;
    height: 42px;
    background-color: #166df7;
    border: none;
    border-radius: 21px;

    a {
      color: #fff;
      font-size: 18px;
    }
  }
}

.breadcrumb {
  display: flex;
  align-items: center;
  color: #333;
  margin: 24px 0 44px;

  a {
    color: #0d1624;
  }

  img {
    margin: 0 10px;
  }

  .icon-right {
    width: 10px;
    height: 12px;
  }

  span {
    color: #999;
  }
}

.desc {
  padding-bottom: 80px;

  h3 {
    font-size: 24px;
    font-weight: 600;
    color: #34383d;
    margin-bottom: 30px;
    text-align: center;
  }

  .post {
    width: 580px;
    height: 150px;
    box-shadow: 0px 4px 24px 0px rgba(32, 34, 36, 0.08);
    padding: 30px;
    box-sizing: border-box;
    display: inline-block;
    margin: 0 20px 20px 0;

    .top {
      display: flex;

      .emergency {
        display: inline-block;
        width: 32px;
        height: 32px;
        background-color: #f3524d;
        border-radius: 4px;
        line-height: 32px;
        text-align: center;
        color: #fff;
        font-size: 20px;
        margin-right: 12px;
      }

      .title {
        color: #333;
        font-size: 24px;
        line-height: 1.3;
      }
    }

    .link {
      margin-top: 39px;

      a {
        color: #166df7;
        font-size: 20px;
        font-weight: 400;
      }
    }
  }
}

.culture {
  background: #fafafa;
  padding: 60px 0 80px;

  .w1200 {
    h3 {
      font-size: 24px;
      font-weight: 600;
      color: #34383d;
      margin-bottom: 30px;
      text-align: center;
    }

    .sPost {
      width: 580px;
      height: 150px;
      box-shadow: 0px 4px 24px 0px rgba(32, 34, 36, 0.08);
      padding: 30px;
      box-sizing: border-box;
      display: inline-block;
      margin: 0 20px 20px 0;

      .top {
        .title {
          color: #333;
          font-size: 24px;
          line-height: 1.3;
        }
      }

      .link {
        margin-top: 39px;

        a {
          color: #166df7;
          font-size: 20px;
          font-weight: 400;
        }
      }
    }
  }
}

.joinText {
  .info {
    display: flex;
    margin-bottom: 200px;

    .require {
      flex: 2;

      h2 {
        font-size: 32px;
      }

      h3 {
        font: 500 20px "PingFang SC";
        color: #34383d;
        margin: 30px 0 3px;
        font-weight: 500;
      }

      p {
        font: 400 14px "PingFang SC";
        line-height: 40px;
      }
    }

    .list {
      //flex: 1;
      padding: 40px 35px;
      box-sizing: border-box;
      width: 244px;
      height: 308px;
      border: 2px solid #dddddd;

      .list-title {
        font-size: 24px;
        font-weight: 600;
        color: #333;
        margin-bottom: 20px;
      }

      .list-box {
        .box-info {
          height: 38px;
          font-size: 14px;
          color: #333;
          line-height: 38px;
          border-bottom: 1px solid #ddd;
          cursor: pointer;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
